<template>
    <div class="bulletin-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <h2 class="table-title">
                    <img src="../../assets/images/main/work-icon.png" alt="" class="title-icon">优秀作业</h2>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%" @row-click="openWork">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center" width="80">
                        </el-table-column>

                        <el-table-column prop="workName" label="作业名称" align="left"></el-table-column>

                        <el-table-column prop="schoolName" label="学校" align="center"></el-table-column>

                        <el-table-column prop="userName" label="教师姓名" width="200px" align="center"></el-table-column>

                        <el-table-column prop="correctingTime" label="批改时间" align="center">
                            <!-- <template slot-scope="scope">
                                {{scope.row}}
                            </template> -->
                        </el-table-column>
                    </el-table>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination>
                </div>
            </div>
        </div>
        <!-- 优秀作业弹窗 start-->
        <el-dialog :title="`优秀作品 —— ${currentWork.userName}`" :visible.sync="showWorkDialog" class="work-dialog">
            <p class="work-title">【{{currentWork.workName}}】</p>
            <StuWork :currentWork='currentWork'></StuWork>
            <span slot="footer" class="dialog-footer">
                <el-button @click="prev">上一个</el-button>
                <el-button type="primary" @click="next">下一个</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import StuWork from '../index/stuwork.vue'
    export default {
        components: {
            StuWork
        },
        data() {
            return {
                id: 89,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                tableData: [],
                showWorkDialog: false,
                currentWork: {
                    workName: '陈独秀'
                },
                currentIndex: null,
                totalPage: null,
            }
        },
        methods: {
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getExcellentWork()
            },
            async getExcellentWork() {

                let params = {
                    projectId: this.$route.params.id,
                    // projectId: 195,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Project.getExcellentWork(params);
                // console.log(resData);
                if (JSON.stringify(resData.data) == '[]' || !resData) {
                    this.tableData = [];
                    return;
                }
                this.tableData = resData.data.list
                this.pages.total = resData.data.totalCount
                this.totalPage = resData.data.totalPage
                return Promise.resolve()
            },
            openWork(row) {
                console.log(row);
                this.showWorkDialog = true;
                this.currentWork = row;
                this.currentIndex = this.tableData.findIndex(item => item.userId == row.userId);
            },
            // 上一题
            prev() {
                console.log('上一题');
                if (!this.currentIndex) {
                    this.$message({
                        message: '当前已经是第一个作业',
                        type: 'warning'
                    });
                } else {
                    this.currentIndex -= 1;
                    this.currentWork = this.tableData[this.currentIndex];
                }
            },
            // 下一题
            next() {
                console.log('下一题');
                if (this.currentIndex == this.tableData.length - 1) {
                    if (this.pages.currentPage == this.totalPage) {
                        this.$message({
                            message: '当前已经是最后一个作业',
                            type: 'warning'
                        });
                    } else {
                        this.currentIndex = 0;
                        // 请求下一页数据
                        console.log('请求下一页数据');
                        this.pages.currentPage++
                        this.getExcellentWork()
                            .then(res => {
                                this.currentWork = this.tableData[this.currentIndex]
                            })
                    }
                } else {
                    this.currentIndex += 1;
                    this.currentWork = this.tableData[this.currentIndex]
                }
            }
        },
        mounted() {
            this.getExcellentWork();
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .bulletin-list {
        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            background: #fff;
            padding: 0 26px 24px;
        }

        .table-title {
            line-height: 68px;
            font-size: 17px;
        }

        .title-icon {
            width: 21px;
            height: 21px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        /deep/ .el-table__row {
            cursor: pointer;
        }


        .work-dialog {
            /deep/ .el-dialog {
                border-radius: 8px;
                // width: 889px;
                width: 733px;
                max-height: 600px;
                overflow: auto;

                /*修改滚动条样式*/
                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 2px;
                }

                &::-webkit-scrollbar-button {
                    height: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #bfbfbf;
                    border-radius: 4px;
                }
            }

            .work-title {
                font-size: 17px;
                margin-bottom: 15px;
            }

            /deep/ .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 20px;

                .el-dialog__title {
                    font-size: 17px;

                    &::before {
                        content: ' ';
                        width: 3px;
                        height: 17px;
                        display: inline-block;
                        background-color: #01A2FD;
                        border-radius: 5px;
                        margin-right: 13px;
                        vertical-align: -2px;
                    }
                }
            }

            /deep/ .el-dialog__body {
                padding-top: 25px;
            }

            /deep/ .el-dialog__footer {
                text-align: center;
            }

            /deep/ .el-button--default {
                color: #01A2FD;
                border-radius: 25px;
                border: 1px solid #01A2FD;
                padding: 0 36px;
                line-height: 33px;
            }

            /deep/ .el-button--primary {
                border-radius: 25px;
                padding: 0 36px;
                line-height: 33px;
                margin-left: 98px;
            }

        }


        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }

            .work-dialog {
                /deep/ .el-dialog {
                    width: 889px;
                }
            }
        }
    }
</style>